import { useState, createContext, useContext } from "react";
import { Page } from "interface/interface";
import guestId from "firebase/guestId";

const PageContext = createContext<any>(null);

interface PageData {
  page: Page;
  isGuest: boolean;
  setPage: (volumn: Page) => void;
}

function PageContextProvider({ children }: any) {
  const [page, setPage] = useState<Page>("start");
  const isGuest = localStorage.getItem("user-quizbot") === guestId;

  const pageData: PageData = {
    page,
    isGuest,
    setPage,
  };

  return (
    <PageContext.Provider value={pageData}>{children}</PageContext.Provider>
  );
}

function usePageContext(): any {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error("Error context undefined");
  }
  return context;
}

export { PageContextProvider, usePageContext };
