import { lazy, Suspense, useEffect, useState } from "react";
import "antd/dist/antd.css";
import { VolumeContextProvider } from "context/VolumeContext";
import { PageContextProvider } from "context/PageContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const GlobalStyle = lazy(() => import("style/GlobalStyle"));
const PageRoute = lazy(() => import("resource/PageRoute"));
const Authentication = lazy(() => import("components/pages/Authentication"));

function App() {
  const auth = getAuth();
  const [session, setSession] = useState<any>({
    isLoggedIn: false,
    currentUser: null,
  });
  const userUID = localStorage.getItem("user-quizbot");

  useEffect(() => {
    const authStateChanged = onAuthStateChanged(auth, (user) => {
      setSession(() => ({
        isLoggedIn: user ? true : false,
        currentUser: user,
      }));
    });
    return () => {
      authStateChanged();
    };
  }, [auth, setSession]);

  return (
    <>
      {session.isLoggedIn && userUID && (
        <Suspense fallback="">
          <GlobalStyle />
          <PageContextProvider>
            <VolumeContextProvider>
              <PageRoute email={session.currentUser?.email} />
            </VolumeContextProvider>
          </PageContextProvider>
        </Suspense>
      )}
      {!session.isLoggedIn && !userUID && (
        <Suspense fallback="">
          <GlobalStyle />
          <Authentication setSession={setSession} />
        </Suspense>
      )}
    </>
  );
}

export default App;
