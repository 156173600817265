import { useState, createContext, useContext } from "react";

const VolumeContext = createContext<any>(null);

interface VolumeData {
  volume: number;
  setVolume: (volumn: number) => void;
}

function VolumeContextProvider({ children }: any) {
  const [volume, setVolume] = useState<number>(1);

  const volumeData: VolumeData = {
    volume: volume / 4,
    setVolume,
  };

  return (
    <VolumeContext.Provider value={volumeData}>
      {children}
    </VolumeContext.Provider>
  );
}

function useVolumeContext(): any {
  const context = useContext(VolumeContext);
  if (context === undefined) {
    throw new Error("Error context undefined");
  }
  return context;
}

export { VolumeContextProvider, useVolumeContext };
