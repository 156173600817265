const firebaseConfig = {
  apiKey: "AIzaSyDgXAW90jyZ1jHfcYvjGcRRpszCcE2MPnI",
  authDomain: "quizbot-project-c8683.firebaseapp.com",
  projectId: "quizbot-project-c8683",
  storageBucket: "quizbot-project-c8683.appspot.com",
  messagingSenderId: "8827778627",
  appId: "1:8827778627:web:f37d80ce7bd11312e1c6f6",
  measurementId: "G-PM94PS3S9W",
  databaseURL:
    "https://quizbot-project-c8683-default-rtdb.asia-southeast1.firebasedatabase.app",
};

export default firebaseConfig;
